<template>
    <v-container>
        <v-row>
            <v-col cols=6><h2 class="primary--text mb-2" style="font-weight:400;">Checklists</h2> </v-col>
            <v-col cols=6 class="text-right"><p class="primary--text">{{ now_date }}</p></v-col>
        </v-row>
        
        <div v-if="projects.length > 0">
            <v-card
            elevation="2"   
            outlined
            class="mb-3"
            v-for="project in projects" v-bind:key="project.id"
            >
            <v-card-title>
                <v-row>
                    <v-col cols=12><h4 class="primary--text">{{ project.name }}</h4></v-col>
                    <!-- <v-col cols=3 style="padding-left:0px !important;"><v-btn x-small elevation="2" :color="getStatus(project.status)">{{ project.status }}</v-btn></v-col> -->
                </v-row>
            </v-card-title>
            <v-card-text style="padding-bottom:0px;">
                <h4 class="primary--text">{{ project.project_code }}</h4>
            </v-card-text>
            <v-card-actions>
                <v-row>
                <v-col cols=6><v-btn style="text-transform: capitalize;" class="success" @click="openEHS(project)">EHS Checklists</v-btn></v-col>
                <v-col cols=6><v-btn style="text-transform: capitalize;" class="primary float-right" @click="openQa(project)">QA Checklists</v-btn></v-col>
                </v-row>
            </v-card-actions>
            </v-card>
        </div>
        <div v-else>
            <v-alert type="error">No projects found</v-alert>
            <v-btn small style="text-transform: capitalize;" class="primary" @click="refreshProjects">Refresh</v-btn>
        </div>
    </v-container>
</template>

<script>
import { mapGetters,mapActions } from 'vuex'
import date from 'date-and-time'
export default {
    name:'Project',
    data: () => ({
        now_date : "",
        user_p : JSON.parse(localStorage.getItem('user'))
    }),
    methods:{
        ... mapActions (['fetchAllProjects']),
        updateProjectStatus (value){
            if(value == "approved"){
                return "success"
            }else if(value == "reject"){
                return "danger"
            }else{
                return "warning"
            }
        },
        openEHS (value) {
            this.$store.commit('setCurrentProject',value)
            this.$router.push('checklists/ehs')
        },
        openQa (value) {
            this.$store.commit('setCurrentProject',value)
            this.$router.push('checklists/qa')
        },
        refreshProjects () {
            this.fetchAllProjects(this.user_p.id)
            location.reload()
        },
        getStatus (status) {
            if(status == "pending") {
                return "yellow"
            }else if(status == "reject") {
                return "red"
            }else if(status == "approved") {
                return "green"
            }else if(status == "closed") {
                return "black--light"
            }else {
                return "yellow"
            }
        }
    },
    computed: {
        ... mapGetters ( {projects : "getAllProjects"})
    },
    mounted(){
        let now  = new Date();
        let now_dates = date.format(now, 'ddd, MMM DD YYYY');
        this.now_date = now_dates 
        this.fetchAllProjects(this.user_p.id)
    }
}
</script>